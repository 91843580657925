import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
    Radio, RadioGroup, FormControlLabel, Backdrop, CircularProgress, Snackbar, Grid, Typography, StepLabel,
    Step, Stepper, Select, FormControl, MenuItem, InputLabel, TextField, Button, FormGroup, Checkbox, Link
} from '@material-ui/core';
import CoursesServices from "../services/courses.service";
import PaymentsServices from "../services/payments.service";
import StudentsServices from "../services/students.service";
import MuiAlert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import { $ } from 'react-jquery-plugin';
import publicIp from "react-public-ip";
import {useState} from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { ContextEnviroment } from '../context/contextEnviroment'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

const { REACT_APP_URL } = process.env;
var urlPago = "";
var requestId_placetopay = "";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    marginTopGrid: {
        marginTop: '20px'
    },
    inputControl: {
        margin: theme.spacing(1)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
}));

function getSteps() {
    return ['Tipo de pago', 'Información', 'Finalización'];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return 'Selecciona el tipo de pagos que vas hacer';
        case 1:
            return 'Completa la siguiente información';
        case 2:
            return 'Finaliza tu proceso de pago';
        default:
            return 'Selecciona un paso';
    }
}

function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

const FormSelection = () => {
    const classes = useStyles();

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [courses, setCourses] = React.useState([]);
    const [validType, setValidType] = React.useState(false);
    const [validCourse, setValidCourse] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [alert, setAlert] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [typeStudent, setTypeStudent] = React.useState(0);
    const [valiStudent, setValiStudent] = React.useState(false);
    const [transaction, setTransaction] = React.useState({});
    const [checkedTerm, setCheckedTerm] = React.useState(false);
    const [textselect, setTextselect] = React.useState('');

    const {
        ['parameter']: [data_, setData_],
      } = React.useContext(ContextEnviroment)

    const [search, setSearch] = useState('');

    const handleChangeTerm = (event) => {
        setCheckedTerm(event.target.checked);
    };

    const handleLoadingClose = () => {
        setLoading(false);
    };

    const proceder_pago = () =>{
        if(urlPago!='' && requestId_placetopay!=''){
            setLoading(true);
            PaymentsServices.Proceedpayment(requestId_placetopay)
                .then(response => {
                    setLoading(false);
                    const data = response.data;
                    if(data.success){
                        window.location.href = urlPago
                    }else{
                        setAlert({ 'severity': 'warning', 'msg': data.msg });
                        setOpen(true);
                    }  
                })
                .catch(e => {
                    setLoading(false);
                    console.log(e);
                });
        }else{
            setAlert({ 'severity': 'warning', 'msg': '¡Url o requestId no obtenido!' });
            setOpen(true);
        }
    };

    const steps = getSteps();

    const validationSchema = Yup.object().shape({
        tipo: Yup.string()
            .required('Required'),
        type_course: Yup.string()
            .required('Required'),
        course: Yup.string()
            .required('Required'),
        total: Yup.number()
            .required('Required'),
        codigo: Yup.string()
            .required('Required'),
        name: Yup.string()
            .required('Required'),
        email: Yup.string()
            .email('email invalido')
            .required('Required'),
        phone: Yup.string()
            .required('Required'),
        typeDocument: Yup.number()
            .required('Required'),
        typeDocument_owner: Yup.number()
            .required('Required'),
        codigo_owner: Yup.string()
            .required('Required'),
        fistname_owner: Yup.string()
            .required('Required'),
        secondname_owner: Yup.string()
            .required('Required'),
        lastname_owner: Yup.string()
            .required('Required'),
        email_owner: Yup.string()
            .email('email invalido')
            .required('Required'),
        phone_owner: Yup.string()
            .required('Required'),
        direction_owner: Yup.string()
            .required('Required'),
        description: Yup.string()
            .required('Required'),
    });

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;

        if (activeStep === 0) {
            if (formik.values.total > 0) {
                if (formik.values.course !== '' && formik.values.course != 0) {
                    if (formik.values.type_pay !== '' && formik.values.type_pay != 0) {
                        
                    } else {
                        setAlert({ 'severity': 'warning', 'msg': '¡Complete todos los campos!' });
                        setOpen(true);
                        return false;
                    }
                } else {
                    setAlert({ 'severity': 'warning', 'msg': '¡Complete todos los campos!' });
                    setOpen(true);
                    return false;
                }
            } else {
                setAlert({ 'severity': 'warning', 'msg': '¡Complete todos los campos!' });
                setOpen(true);
                return false;
            }
        }

        if (activeStep === 1) {
            if (formik.values.codigo_owner !== '' && formik.values.codigo !== '' && formik.values.fistname_owner !== ''
                && formik.values.secondname_owner !== '' && formik.values.lastname_owner !== '' && formik.values.email_owner !== ''
                && formik.values.email !== '' && formik.values.phone !== '' && formik.values.phone_owner !== '' && formik.values.direction_owner !== '' && checkedTerm === true) {

            } else {
                if (checkedTerm === false) {
                    setAlert({ 'severity': 'warning', 'msg': '¡Acepte los Términos y Condiciones, Politica de Protección de Datos!' });
                    setOpen(true);
                    return false;
                } else {
                    setAlert({ 'severity': 'warning', 'msg': '¡Complete todos los campos!' });
                    setOpen(true);
                    return false;
                }
            }
        }

        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    function validateDocument(type_document, document_person, person){
        
        if(type_document == 0){
            setAlert({ 'severity': 'warning', 'msg': 'El Tipo Documento del '+person+' no esta seleccionado!' });
            setOpen(true);
            return false;
        }

        if(document_person.toString().trim().length == 0){
            setAlert({ 'severity': 'warning', 'msg': 'El Documento del '+person+' esta vacio!' });
            setOpen(true);
            return false;
        }

        if(type_document==1){
            if(document_person.toString().length != 10){
                setAlert({ 'severity': 'warning', 'msg': 'El documento del '+person+' no tiene la longitud de una Cedula (10 digitos)!' });
                setOpen(true);
                return false;
            }
        }else if(type_document==2){
            if(document_person.toString().length != 13){
                setAlert({ 'severity': 'warning', 'msg': 'El documento del '+person+' no tiene la longitud de un Ruc (13 digitos)!' });
                setOpen(true);
                return false;
            }
        }else if(type_document==3){
            if(!(document_person.toString().length >= 4 && document_person.toString().length <= 16)){
                setAlert({ 'severity': 'warning', 'msg': 'El documento del '+person+' no tiene la longitud de un Pasaporte (Entre 4 y 16 digitos)!' });
                setOpen(true);
                return false;
            }
        }
        return true;
    }
    
    function validatePhone(phone, person){
        var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        
        if(phone.toString().trim().length==0){
            setAlert({ 'severity': 'warning', 'msg': 'El Teléfono del '+person+' esta vacio!' });
            setOpen(true);
            return false;
        }

        if (!pattern.test(phone)) {
            setAlert({ 'severity': 'warning', 'msg': 'El teléfono debe del '+person+' debe tener solo números!' });
            setOpen(true);
            return false;
        } else if (phone.toString().trim().length != 10) {
            setAlert({ 'severity': 'warning', 'msg': 'Porfavor ingresa un número de teléfono valido del '+person+' (10 digitos).' });
            setOpen(true);
            return false;
        }
        return true;
    }

    function validateNameLastNamePlayer(type_document, fistname, lastname){
        if(type_document==3){
            if(fistname.trim().length == 0){
                setAlert({ 'severity': 'warning', 'msg': 'Ingrese La Razón Social o Nombre Comercial!' });
                setOpen(true);
                return false;
            }
        }else{
            if(fistname.trim().length == 0){
                setAlert({ 'severity': 'warning', 'msg': 'Ingrese los nombres del Estudiante!' });
                setOpen(true);
                return false;
            }
            
            if(lastname.trim().length == 0){
                setAlert({ 'severity': 'warning', 'msg': 'Ingrese los apellidos del Estudiante!' });
                setOpen(true);
                return false;
            }

        }
        return true;
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    function validateNameLastNameBuyer(type_document, fistname, secondname, lastname){
        if(type_document==3){
            if(fistname.trim().length == 0){
                setAlert({ 'severity': 'warning', 'msg': 'Ingrese La Razón Social o Nombre Comercial!' });
                setOpen(true);
                return false;
            }
        }else{
            if(fistname.trim().length == 0){
                setAlert({ 'severity': 'warning', 'msg': 'Ingrese el primer nombre del Propietario de la Tarjeta!' });
                setOpen(true);
                return false;
            }
            if(secondname.trim().length == 0){
                setAlert({ 'severity': 'warning', 'msg': 'Ingrese el segundo nombre del Propietario de la Tarjeta!' });
                setOpen(true);
                return false;
            }
            if(lastname.trim().length == 0){
                setAlert({ 'severity': 'warning', 'msg': 'Ingrese los apellidos del Propietario de la Tarjeta!' });
                setOpen(true);
                return false;
            }

        }
        return true;
    }

    const handleStep2 = () => {
        //validacion datos estudiante

        //validar documento estudiante
        if(!validateDocument(formik.values.typeDocument, formik.values.codigo, 'Estudiante'))
            return;

        //validar nombres apellidos estudiante
        if(!validateNameLastNamePlayer(formik.values.typeDocument, formik.values.name, formik.values.lastname))
            return;

        //validar correo estudiante
        if (!isValidEmail(formik.values.email)) {
            setAlert({ 'severity': 'warning', 'msg': 'El correo del Estudiante es invalido!' });
            setOpen(true);
            return;
        }

        //validar telefono estudiante
        if(!validatePhone(formik.values.phone, 'Estudiante'))
            return;

        //validacion datos propietario tarjeta

        //validar documento propietario tarjeta
        if(!validateDocument(formik.values.typeDocument_owner, formik.values.codigo_owner, 'Propietario de la Tarjeta'))
            return;

        //validar nombres apellidos propietario tarjeta
        if(!validateNameLastNameBuyer(formik.values.typeDocument_owner, formik.values.fistname_owner, formik.values.secondname_owner, formik.values.lastname_owner))
            return;

        //validar correo propiertario tarjeta
        if (!isValidEmail(formik.values.email_owner)) {
            setAlert({ 'severity': 'warning', 'msg': 'El correo del Propietario de la Tarjeta es invalido!' });
            setOpen(true);
            return;
        }

        //validar telefono propietario tarjeta
        //console.log(formik.values.phone_owner);
        if(!validatePhone(formik.values.phone_owner, 'Propietario de la Tarjeta'))
            return;

        if (checkedTerm === false) {
            setAlert({ 'severity': 'warning', 'msg': '¡Acepte los Términos y Condiciones, Politica de Protección de Datos!' });
            setOpen(true);
            return;
        }

        takeCheckoutId(formik.values.total, formik.values.fistname_owner, formik.values.secondname_owner, formik.values.lastname_owner,
            formik.values.codigo_owner, formik.values.email_owner, formik.values.country_dialCode_owner, formik.values.phone_owner, formik.values.direction_owner,
            formik.values.tipo_name, formik.values.type_pay_name, formik.values.course_name, formik.values.tipo, formik.values.type_pay, formik.values.course,
            formik.values.codigo, formik.values.name, formik.values.email, formik.values.country_dialCode, formik.values.phone, formik.values.typeDocument,
            formik.values.typeDocument_owner, formik.values.lastname, formik.values.description);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    /*const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
        });
    };*/

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleTypeCourse = (e) => {
        let value = e.target.value;
        value = value.split('****')
        formik.setFieldValue('tipo_name', value[1]);
        formik.setFieldValue('type_course', e.target.value);
        takeCourses(value[0]);
        setValidCourse(true);

    };

    const handleChangeCourse = (e) => {
        let value = e.target.value;
        value = value.split('****')
        formik.setFieldValue('course_name', value[1]);
        formik.setFieldValue('course', e.target.value);
    };

    const handleChangeTypePay = (e) => {
        let value = e.target.value;
        value = value.split('****')
        formik.setFieldValue('type_pay', e.target.value);
        formik.setFieldValue('type_pay_name', value[1]);
    };

    const handleTypeDocument = (e) => {
        formik.setFieldValue('typeDocument', e.target.value);
    };

    const handleTypeDocumentOwner = (e) => {
        formik.setFieldValue('typeDocument_owner', e.target.value);
    };

    const handleType = (e) => {
        let value = e.target.value;
        value = value.split('****')
        formik.setFieldValue('tipo', e.target.value);
        formik.setFieldValue('tipo_name', value[1]);
        if (e.target.value !== '3****Cursos') {
            takeCourses(value[0]);
            setValidType(false);
            setValidCourse(true);
        } else {
            setValidType(true);
            setValidCourse(false);
        }
    };

    const takeCourses = (type) => {
        CoursesServices.getCoursesType(type)
            .then(response => {
                setCourses(response.data)
            })
            .catch(e => {
                console.log(e);
            });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const takeCheckoutId = async (total, fistname_owner, secondname_owner, lastname_owner, codigo_owner, email_owner, codigo_telefono_owner, phone_owner,
        direction_owner, tipo_name, type_pay_name, course_name, tipo, type_pay, course,
        codigo, name, email, codigo_telefono, phone, typeDocument, typeDocument_owner, lastname, description) => {
        setLoading(true);

        const ipv4 = await publicIp.v4() || "200.93.207.22";

        PaymentsServices.getCheckoutId(total, fistname_owner, secondname_owner, lastname_owner, codigo_owner, email_owner, codigo_telefono_owner, phone_owner,
            direction_owner, tipo_name, type_pay_name, course_name, tipo, type_pay, course, codigo, name,
            email, codigo_telefono, phone, typeDocument, typeDocument_owner, lastname, description, ipv4, data_.parametro)
            .then(response => {
                setLoading(false);
                const data = response.data;
                if(data_.parametro==1){
                    if (data.result.code === '000.200.100') {
                        setTransaction({ id: data.id, ndc: data.ndc, buildNumber: data.buildNumber, code: data.result.code });
                        addScritp(data.id);
                        handleNext();
                    } else {
                        setAlert({ 'severity': 'warning', 'msg': JSON.stringify(response.data.result) });
                        setOpen(true);
                    }
                }else{
                    if(data.success){
                        requestId_placetopay = data.requestId_placetopay;
                        urlPago = data.processUrl;
                        handleNext();
                    }else{
                        setAlert({ 'severity': 'warning', 'msg': data.msg });
                        setOpen(true);
                    }  
                }
            })
            .catch(e => {
                setLoading(false);
                console.log(e);
            });
    }

    const handleTypeStudent = (e) => {
        setTypeStudent(e.target.value)
    }

    const takeStudenSearch = (search) => {
        setLoading(true);
        setValiStudent(false);
        StudentsServices.search(search)
            .then(response => {
                setLoading(false);
                const data = response.data;
                if (data.length > 0) {
                    formik.setFieldValue('typeDocument', 1);
                    formik.setFieldValue('codigo', data[0].document);
                    formik.setFieldValue('name', data[0].name);
                    formik.setFieldValue('lastname', data[0].lastname);
                    formik.setFieldValue('email', data[0].email);
                    formik.setFieldValue('phone', data[0].phone);
                    formik.setFieldValue('country_dialCode', "593");
                    setValiStudent(true);
                } else {
                    setAlert({ 'severity': 'warning', 'msg': 'No se encontrarón datos, intentelo nuevamente.' });
                    setOpen(true);
                }
            })
            .catch(e => {
                setLoading(false);
            });
    }

    const addScritp = (code) => {
        const script = document.createElement("script");
        var jstext = `var wpwlOptions = {   onReady: function() { var numberOfDiferido = '<div class="wpwl-label wpwl-label-custom" style="display:inline-block">Diferidos:</div><div class="wpwl-wrapper wpwl-wrapper-custom" style="display:inline-block"><select id="numberOfInstallments" name="recurring.numberOfInstallments"><option value="0">Corriente</option><option value="3">3</option><option value="6">6</option><option value="9">9</option><option value="12">12</option></select>  <select style="visibility: hidden;" id="shopper_interes" name="customParameters[SHOPPER_TIPOCREDITO]"><option value="00">No</option><option value="02">Si</option></select> </div>'; $('form.wpwl-form-card').find('.wpwl-button').before(numberOfDiferido); $('#numberOfInstallments').change(function(){var value = $(this).val();if (value != 0) {$('#shopper_interes').val('02').trigger('change');} else {$('#shopper_interes').val('00').trigger('change'); }}); var datafast='<br/><br/><img src="https://www.datafast.com.ec/images/verified.png" style="display:block;margin:0 auto; width:100%;">'; $('form.wpwl-form-card').find('.wpwl-button').before(datafast);   },  style: "card",  locale: "es",  labels: {cvv: "CVV", cardHolder: "Nombre(Igual que en la tarjeta)"}}`;
        script.textContent = jstext;
        document.body.appendChild(script);

        const script2 = document.createElement("script");
        script2.src = "https://oppwa.com/v1/paymentWidgets.js?checkoutId=" + code;
        script2.async = true;
        document.body.appendChild(script2);
    }

    const formik = useFormik({
        initialValues: {
            tipo: '',
            tipo_name: '',
            type_course: '',
            description: '',
            course: 0,
            course_name: '',
            type_pay: 0,
            type_pay_name: '',
            total: 0,
            codigo: '',
            name: '',
            lastname: '',
            email: '',
            country_dialCode: '',
            phone: '',
            typeDocument: 0,
            typeDocument_owner: 0,
            codigo_owner: '',
            fistname_owner: '',
            secondname_owner: '',
            lastname_owner: '',
            email_owner: '',
            country_dialCode_owner: '',
            phone_owner: '',
            direction_owner: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // alert(JSON.stringify(values, null, 2));
        },
    });

    return (
        <div>
            <div id="divimg"></div>
            <div className={classes.root}>
                <div >
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};

                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div>
                        {activeStep === 0 ? (
                            <div className={classes.root}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="tipo">Tipo</InputLabel>
                                            <Select
                                                labelId="tipo"
                                                id="tipo"
                                                value={formik.values.tipo}
                                                onChange={handleType}
                                                error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                                                helpertext={formik.touched.tipo && formik.errors.tipo}
                                            >
                                                <MenuItem value={'1****Grado'}>Grado</MenuItem>
                                                <MenuItem value={'2****Posgrado'}>Posgrado</MenuItem>
                                                <MenuItem value={'7****PuceTec'}>Puce Tec</MenuItem>
                                                {/*<MenuItem value={'3****Cursos'}>Cursos</MenuItem>*/}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {validType ? (
                                        <Grid item xs={12} sm={6}>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="type_course">Seleccionar tipo de cursos</InputLabel>
                                                <Select
                                                    id="type_course"
                                                    value={formik.values.type_course}
                                                    onChange={handleTypeCourse}
                                                    error={formik.touched.type_course && Boolean(formik.errors.type_course)}
                                                    helpertext={formik.touched.type_course && formik.errors.type_course}
                                                >
                                                    <MenuItem value={'3****Programas Virtuales'}>Programas Virtuales</MenuItem>
                                                    <MenuItem value={'4****Cursos Online'}>Cursos Online</MenuItem>
                                                    <MenuItem value={'5****Escuela de Negocios'}>Escuela de Negocios</MenuItem>
                                                    <MenuItem value={'6****Certificaciones'}>Certificaciones</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>) : <></>}
                                    {validCourse ? (
                                        <Grid item xs={12} sm={6}>
                                            <FormControl className={classes.formControl}>
                                                {formik.values.tipo === '1****Grado' ? (<InputLabel id="course">Seleccione carrera</InputLabel>) : <></>}
                                                {formik.values.tipo === '2****Posgrado' ? (<InputLabel id="course">Seleccione programa</InputLabel>) : <></>}
                                                {formik.values.tipo === '3****Cursos' ? (<InputLabel id="course">Seleccione curso</InputLabel>) : <></>}

                                                <Select
                                                    id="course"
                                                    value={formik.values.course}
                                                    onChange={handleChangeCourse}
                                                    error={formik.touched.course && Boolean(formik.errors.course)}
                                                    helpertext={formik.touched.course && formik.errors.course}
                                                >
                                                    {courses.map((course, index) => {
                                                        return (
                                                            <MenuItem key={index} value={course.id + '****' + course.title}>
                                                                {course.title}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>) : <></>}
                                    <Grid item xs={12} sm={6} >
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="type_pay">Tipo pago</InputLabel>
                                            <Select
                                                id="type_pay"
                                                value={formik.values.type_pay}
                                                onChange={handleChangeTypePay}
                                                error={formik.touched.type_pay && Boolean(formik.errors.type_pay)}
                                                helpertext={formik.touched.type_pay && formik.errors.type_pay}
                                            >
                                                <MenuItem value={'1****Pago de cuota y convenio'}>Pago de cuota y convenio</MenuItem>
                                                <MenuItem value={'2****Pago de matrícula y arancel'}>Pago de matrícula y arancel</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                id="description"
                                                name="description"
                                                label="Descripción del pago"
                                                type="text"
                                                value={formik.values.description}
                                                onChange={e => {
                                                    let description = e.target.value;
                                                    formik.setFieldValue('description', description.toUpperCase()); //To convert Lower Case);
                                                }}
                                                error={formik.touched.description && Boolean(formik.errors.description)}
                                                helpertext={formik.touched.description && formik.errors.description}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                        <FormControl className={classes.formControl}>

                                            <TextField
                                                id="total"
                                                name="total"
                                                label="Valor a pagar (Ejem: 1220.00)"
                                                type="text"
                                                value={formik.values.total}
                                                onChange={e => {
                                                    const total = e.target.value;
                                                    if (!total || total.match(/^\d{1,}(\.\d{0,4})?$/)) {
                                                        //formik.setFieldValue('total', total);
                                                        formik.setFieldValue('total', total);
                                                    }
                                                  }}
                                                onBlur={(e) => {
                                                    formik.setFieldValue('total', parseFloat(formik.values.total));
                                                }}
                                                error={formik.touched.total && Boolean(formik.errors.total)}
                                                helpertext={formik.touched.total && formik.errors.total}
                                            />

                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid className={classes.marginTopGrid} container justify="flex-end" alignItems="center">
                                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                        Atras
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                    >
                                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                                    </Button>
                                </Grid>
                            </div>
                        ) : <></>}

                        {activeStep === 1 ? (
                            <div className={classes.root}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.instructions}>Datos del Estudiante</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography >¿Eres estudiante nuevo o antiguo?</Typography>
                                        <RadioGroup aria-label="type_student" name="customized-radios" onChange={handleTypeStudent}>
                                            <FormControlLabel value="1" control={<StyledRadio />} label="Nuevo" />
                                            <FormControlLabel value="2" control={<StyledRadio />} label="Antiguo" />
                                        </RadioGroup>
                                    </Grid>

                                    {typeStudent === '2' ? (
                                        <>
                                            <Grid item xs={12} >
                                                <FormControl className={classes.formControl}>
                                                    <TextField
                                                        id="cedula"
                                                        name="cedula"
                                                        label="Búsqueda por cédula"
                                                        type="text"
                                                        inputProps = {{maxLength:10}}
                                                        value={search}
                                                        className={classes.formControl}
                                                        onChange={e => {
                                                            let search = e.target.value;
                                                            if (!search || search.match(/^[0-9\b]+$/)) {
                                                                setSearch(search);
                                                                if (search.length === 10) {
                                                                    takeStudenSearch(search);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>
                                    ) : <></>}

                                    {typeStudent === '1' || valiStudent ? (
                                        <>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel id="typeDocument">Tipo de documento</InputLabel>
                                                    <Select
                                                        labelId="typeDocument"
                                                        id="typeDocument"
                                                        value={formik.values.typeDocument}
                                                        onChange={handleTypeDocument}
                                                        error={formik.touched.typeDocument && Boolean(formik.errors.typeDocument)}
                                                        helpertext={formik.touched.typeDocument && formik.errors.typeDocument}
                                                    >
                                                        <MenuItem value={1}>Cédula</MenuItem>
                                                        <MenuItem value={2}>Ruc</MenuItem>
                                                        <MenuItem value={3}>Pasaporte</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="codigo"
                                                    name="codigo"
                                                    label="Cédula / Ruc / pasaporte"
                                                    className={classes.formControl}
                                                    value={formik.values.codigo}
                                                    inputProps = {{maxLength: 16}}
                                                    onChange={e => {
                                                        let codigo = e.target.value;
                                                        if(formik.values.typeDocument==1 || formik.values.typeDocument==2){
                                                            if (!codigo || codigo.match(/^[0-9\b]+$/)) {
                                                                formik.setFieldValue('codigo', codigo);
                                                            }
                                                        }else{
                                                            const re = /^[A-Za-z 0-9]+$/;
                                                            if (!codigo || re.test(codigo)) {
                                                                formik.setFieldValue('codigo', codigo);
                                                            }
                                                            //formik.setFieldValue('codigo', codigo);
                                                        }
                                                    }}
                                                    error={formik.touched.codigo && Boolean(formik.errors.codigo)}
                                                    helpertext={formik.touched.codigo && formik.errors.codigo}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} >
                                                <TextField
                                                    id="name"
                                                    name="name"
                                                    label="Nombres"
                                                    className={classes.formControl}
                                                    value={formik.values.name}
                                                    type="text"
                                                    //onChange={formik.handleChange}
                                                    onChange={e => {
                                                        let name = e.target.value;
                                                        const re = /^[A-Za-z ñÑáéíóúÁÉÍÓÚ]+$/;
                                                        if (re.test(name)) {
                                                            formik.setFieldValue('name', name.toUpperCase());
                                                        }
                                                    }}
                                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                                    helpertext={formik.touched.name && formik.errors.name}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} >
                                                <TextField
                                                    id="lastname"
                                                    name="lastname"
                                                    label="Apellidos"
                                                    className={classes.formControl}
                                                    value={formik.values.lastname}
                                                    //onChange={formik.handleChange}
                                                    onChange={e => {
                                                        let lastname = e.target.value;
                                                        const re = /^[A-Za-z ñÑáéíóúÁÉÍÓÚ]+$/;
                                                        if (re.test(lastname)) {
                                                            formik.setFieldValue('lastname', lastname.toUpperCase());
                                                        }
                                                    }}
                                                    error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                                                    helpertext={formik.touched.lastname && formik.errors.lastname}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} >
                                                <TextField
                                                    id="email"
                                                    name="email"
                                                    label="Correo"
                                                    type="email"
                                                    value={formik.values.email}
                                                    className={classes.formControl}
                                                    onChange={e => {
                                                        let email = e.target.value;
                                                        formik.setFieldValue('email', email.toLowerCase()); //To convert Lower Case);
                                                    }}
                                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                                    helpertext={formik.touched.email && formik.errors.email}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} >
                                            <PhoneInput
                                                id="phone"
                                                name="phone"
                                                type="phone"
                                                label={"Celular"}
                                                placeholder={"0900000000"}
                                                inputProps = {{maxLength: 13}}
                                                country={'ec'}
                                                className={classes.formControl}
                                                disableDropdown={true}
                                                disableCountryCode={true}
                                                value={formik.values.phone}
                                                onChange={(value, country, e, formattedValue) =>{ 
                                                    //console.log(country.dialCode+" "+value+" "+e+" "+formattedValue); 
                                                    formik.setFieldValue('country_dialCode', country.dialCode);
                                                    formik.setFieldValue('phone', value);
                                                }}/>
                                         
                                                {/* <TextField
                                                    id="phone"
                                                    name="phone"
                                                    label="Celular"
                                                    type="phone"
                                                    maxLength="10"
                                                    value={formik.values.phone}
                                                    className={classes.formControl}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                                    helpertext={formik.touched.phone && formik.errors.phone}
                                                /> */}
                                            </Grid>
                                        </>
                                    ) : <></>}
                                    {valiStudent || typeStudent === '1' ? (
                                        <>
                                            <Grid item xs={12}>
                                                <Typography className={classes.instructions}><strong>Datos del propietario de la tarjeta</strong></Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel id="typeDocument">Tipo de documento</InputLabel>
                                                    {data_.parametro==1 ?
                                                        <Select
                                                        labelId="typeDocument"
                                                        id="typeDocument"
                                                        value={formik.values.typeDocument_owner}
                                                        onChange={handleTypeDocumentOwner}
                                                        error={formik.touched.typeDocument_owner && Boolean(formik.errors.typeDocument_owner)}
                                                        helpertext={formik.touched.typeDocument_owner && formik.errors.typeDocument_owner}
                                                        >
                                                            <MenuItem value={1}>Cédula</MenuItem>
                                                            <MenuItem value={3}>Pasaporte / Dni</MenuItem>
                                                            
                                                        </Select>
                                                    :
                                                        <Select
                                                        labelId="typeDocument"
                                                        id="typeDocument"
                                                        value={formik.values.typeDocument_owner}
                                                        onChange={handleTypeDocumentOwner}
                                                        error={formik.touched.typeDocument_owner && Boolean(formik.errors.typeDocument_owner)}
                                                        helpertext={formik.touched.typeDocument_owner && formik.errors.typeDocument_owner}
                                                        >
                                                            <MenuItem value={1}>Cédula</MenuItem>
                                                            <MenuItem value={2}>Ruc</MenuItem>
                                                            <MenuItem value={3}>Pasaporte</MenuItem>
                                                            
                                                        </Select>
                                                    }
                                                    
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="codigo_owner"
                                                    name="codigo_owner"
                                                    label="Documento"
                                                    type="text"
                                                    className={classes.formControl}
                                                    value={formik.values.codigo_owner}
                                                    inputProps = {{maxLength: 16}}
                                                    onChange={e => {
                                                        let codigo = e.target.value;
                                                        if(formik.values.typeDocument_owner==1 || formik.values.typeDocument_owner==2){
                                                            if (!codigo || codigo.match(/^[0-9\b]+$/)) {
                                                                formik.setFieldValue('codigo_owner', codigo);
                                                            }
                                                        }else{
                                                            const re = /^[A-Za-z 0-9]+$/;
                                                            if (!codigo || re.test(codigo)) {
                                                                formik.setFieldValue('codigo_owner', codigo);
                                                            }
                                                            //formik.setFieldValue('codigo_owner', codigo);
                                                        }
                                                    }}
                                                    //onChange={formik.handleChange}
                                                    error={formik.touched.codigo_owner && Boolean(formik.errors.codigo_owner)}
                                                    helpertext={formik.touched.codigo_owner && formik.errors.codigo_owner}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} >
                                                <TextField
                                                    id="fistname_owner"
                                                    name="fistname_owner"
                                                    label="Primer Nombre"
                                                    className={classes.formControl}
                                                    value={formik.values.fistname_owner}
                                                    type="text"
                                                    //onChange={formik.handleChange}
                                                    onChange={e => {
                                                        let fistname_owner = e.target.value;
                                                        const re = (formik.values.typeDocument_owner!=2) ? /^[A-Za-z ñÑáéíóúÁÉÍÓÚ]+$/ : /^[A-Za-z ñÑáéíóúÁÉÍÓÚ0-9]+$/;
                                                        if (!fistname_owner || re.test(fistname_owner)) {
                                                            formik.setFieldValue('fistname_owner', fistname_owner.toUpperCase());
                                                        }
                                                    }}
                                                    error={formik.touched.fistname_owner && Boolean(formik.errors.fistname_owner)}
                                                    helpertext={formik.touched.fistname_owner && formik.errors.fistname_owner}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} >
                                                <TextField
                                                    id="secondname_owner"
                                                    name="secondname_owner"
                                                    label="Segundo Nombre"
                                                    className={classes.formControl}
                                                    value={formik.values.secondname_owner}
                                                    type="text"
                                                    //onChange={formik.handleChange}
                                                    onChange={e => {
                                                        let secondname_owner = e.target.value;
                                                        const re = (formik.values.typeDocument_owner!=2) ? /^[A-Za-z ñÑáéíóúÁÉÍÓÚ]+$/ : /^[A-Za-z ñÑáéíóúÁÉÍÓÚ0-9]+$/;
                                                        if (!secondname_owner || re.test(secondname_owner)) {
                                                            formik.setFieldValue('secondname_owner', secondname_owner.toUpperCase());
                                                        }
                                                    }}
                                                    error={formik.touched.secondname_owner && Boolean(formik.errors.secondname_owner)}
                                                    helpertext={formik.touched.secondname_owner && formik.errors.secondname_owner}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} >
                                                <TextField
                                                    id="lastname_owner"
                                                    name="lastname_owner"
                                                    label="Apellidos"
                                                    value={formik.values.lastname_owner}
                                                    className={classes.formControl}
                                                    type="text"
                                                    //onChange={formik.handleChange}
                                                    onChange={e => {
                                                        let lastname_owner = e.target.value;
                                                        const re = (formik.values.typeDocument_owner!=2) ? /^[A-Za-z ñÑáéíóúÁÉÍÓÚ]+$/ : /^[A-Za-z ñÑáéíóúÁÉÍÓÚ0-9]+$/;
                                                        if (!lastname_owner || re.test(lastname_owner)) {
                                                            formik.setFieldValue('lastname_owner', lastname_owner.toUpperCase());
                                                        }
                                                    }}
                                                    error={formik.touched.lastname_owner && Boolean(formik.errors.lastname_owner)}
                                                    helpertext={formik.touched.lastname_owner && formik.errors.lastname_owner}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} >
                                                <TextField
                                                    id="email_owner"
                                                    name="email_owner"
                                                    label="Correo"
                                                    type="email"
                                                    value={formik.values.email_owner}
                                                    className={classes.formControl}
                                                    onChange={e => {
                                                        let email_owner = e.target.value;
                                                        formik.setFieldValue('email_owner', email_owner.toLowerCase()); //To convert Lower Case);
                                                    }}
                                                    error={formik.touched.email_owner && Boolean(formik.errors.email_owner)}
                                                    helpertext={formik.touched.email_owner && formik.errors.email_owner}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} >
                                            <PhoneInput
                                                id="phone_owner"
                                                name="phone_owner"
                                                type="phone"
                                                label={"Celular"}
                                                placeholder={"0900000000"}
                                                inputProps = {{maxLength: 13}}
                                                country={'ec'}
                                                className={classes.formControl}
                                                disableDropdown={true}
                                                disableCountryCode={true}
                                                value={formik.values.phone_owner}
                                                onChange={(value, country, e, formattedValue) =>{ 
                                                    //console.log(country.dialCode+" "+value+" "+e+" "+formattedValue); 
                                                    formik.setFieldValue('country_dialCode_owner', country.dialCode);
                                                    formik.setFieldValue('phone_owner', value);
                                                }}/>
                                                {/* <TextField
                                                    id="phone_owner"
                                                    name="phone_owner"
                                                    label="Celular"
                                                    type="phone"
                                                    maxLength="10"
                                                    value={formik.values.phone_owner}
                                                    className={classes.formControl}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.phone_owner && Boolean(formik.errors.phone_owner)}
                                                    helpertext={formik.touched.phone_owner && formik.errors.phone_owner}
                                                /> */}
                                            </Grid>

                                            <Grid item xs={12} >
                                                <TextField
                                                    id="direction_owner"
                                                    name="direction_owner"
                                                    label="Dirección"
                                                    value={formik.values.direction_owner}
                                                    className={classes.formControl}
                                                    type="text"
                                                    //onChange={formik.handleChange}
                                                    onChange={e => {
                                                        let direction_owner = e.target.value;
                                                        const re = /^[A-Za-z ñÑáéíóúÁÉÍÓÚ0-9]+$/;
                                                        if (!direction_owner || re.test(direction_owner)) {
                                                            formik.setFieldValue('direction_owner', direction_owner.toUpperCase());
                                                        }
                                                    }}
                                                    error={formik.touched.direction_owner && Boolean(formik.errors.direction_owner)}
                                                    helpertext={formik.touched.direction_owner && formik.errors.direction_owner}
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <FormGroup aria-label="position" row>

                                                {data_.parametro == 1 ?
                                                    <FormControlLabel
                                                        value="end"
                                                        control={<Checkbox checked={checkedTerm} onChange={handleChangeTerm} color="primary" />}
                                                        label={<Link href="/terminos-y-condiciones" target="_BLANK">Acepto Términos y Condiciones</Link>}
                                                        labelPlacement="end"
                                                    />
                                                :
                                                    <FormControlLabel
                                                        value="end"
                                                        control={<Checkbox checked={checkedTerm} onChange={handleChangeTerm} color="primary" />}
                                                        label={<span>Acepto <Link href="/terminos-y-condiciones" target="_BLANK">Términos y Condiciones</Link>, <Link href="/politica-proteccion-datos" target="_BLANK">Politica de Protección de Datos</Link></span>}
                                                        labelPlacement="end"
                                                    />
                                                }

                                                    

                                                </FormGroup>
                                            </Grid>
                                            <Grid className={classes.marginTopGrid} container justify="flex-end" alignItems="center">
                                                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                                    Atras
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleStep2}
                                                    className={classes.button}
                                                >
                                                    {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                                                </Button>
                                            </Grid>
                                        </>
                                    ) : <></>}
                                </Grid>

                            </div>
                        ) : <></>}

                        {activeStep === 2 ? (
                            data_.parametro == 1 ?
                            (<div>
                                <Typography className={classes.instructions}>
                                    Ingrese los datos como están en su tarjeta de crédito/débito.
                                </Typography>
                                <br />
                                <form action={`${REACT_APP_URL}/payment`} class="paymentWidgets" data-brands="VISA MASTER DINERS DISCOVER AMEX">
                                </form>


                                <Button onClick={handleReset} className={classes.button}>Atras</Button>
                                {/*<Button color="primary" variant="contained" fullWidth type="submit">Enviar </Button>*/}
                            </div>)
                            : data_.parametro == 2 ? 
                            (<div>
                                <div className={classes.divBank}>
                                    <img style={{height: 100, justifyContent: "center", alignItems: "center", textAlign: "center"}} className={classes.imgBank} src={'https://static.placetopay.com/placetopay-logo.svg'} />
                                </div>
                                <Typography style={{marginTop: "20px"}} className={classes.instructions}>
                                    Usted sera redireccionado al sitio seguro de Placetopay cuando confirme la orden.
                                </Typography>
                                <br />
                                <div className={classes.divBank} style={{justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                                    <img style={{height: 50, justifyContent: "center", alignItems: "center", textAlign: "center"}} className={classes.imgBank} src={window.location.origin + '/logos/franquicias.png'} />
                                </div>

                                <Card className={classes.root} style={{marginTop: "20px"}}>
                                <CardContent>
                                    <Typography gutterBottom color="textSecondary" variant="h5" component="h2" style={{justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                                    <strong>Total a Pagar</strong>
                                    </Typography>
                                    <Typography variant="h2" component="h2" style={{justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                                    <strong>USD ${formik.values.total}</strong>
                                    </Typography>
                                </CardContent>
                                </Card>

                                <Grid className={classes.marginTopGrid} container justify="flex-end" alignItems="center">
                                                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                                    Atras
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                    //href={urlPago}
                                                    onClick={proceder_pago}
                                                >
                                                    Continuar
                                                </Button>
                                            </Grid>
                                {/*<Button color="primary" variant="contained" fullWidth type="submit">Enviar </Button>*/}
                            </div>) : <></>
                            
                        ) : <></>}
                    </div>

                </div>

            </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alert.severity}>
                    {alert.msg}
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={loading} onClick={handleLoadingClose}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default FormSelection;